.newsListInfo .framePage .framePage-body .p1 {
  line-height: 25px;
  margin-left: 20px;
}
.newsListInfo .framePage .framePage-body .p2 {
  font-size: 12px;
  color: #909399;
  margin-left: 20px;
}
.newsListInfo .framePage .framePage-body .p3 {
  line-height: 35px;
  border-top: 1px solid #c0c4cc;
  margin-top: 20px;
  margin-left: 20px;
}
.newsListInfo .framePage .framePage-body .p3 .ljsh {
  color: #409eff;
  font-size: 12px;
  cursor: pointer;
  margin-left: 10px;
}
.newsListInfo .framePage .framePage-body .el-button {
  width: 120px;
  margin-left: 20px;
  margin-top: 40px;
}
